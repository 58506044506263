<template>
  <n-config-provider v-if="isNaiveUI(props.type)" :theme-overrides="themeOverrides" abstract>
    <n-alert :type="props.type" :bordered="false" :show-icon="!!props.icon" v-bind="$attrs">
      <template v-if="props.icon" #icon>
        <svg-icon :name="props.icon" class="w-7 h-7 flex-shrink-0" />
      </template>
      <div :class="[!props.icon && 'ml-5']">
        <slot></slot>
      </div>
    </n-alert>
  </n-config-provider>
  <div class="custom-alert rounded" :class="customAlertClass" v-bind="$attrs">
    <div v-if="!isNaiveUI(props.type)" class="pl-5 py-4 flex items-center">
      <svg-icon v-if="props.icon" :name="props.icon" class="w-6 h-6 flex-shrink-0 mr-3" />
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { GlobalThemeOverrides, AlertProps } from 'naive-ui'
import { NAlert, NConfigProvider } from 'naive-ui'

export type NDAlertType = AlertProps['type'] | 'favorite'

interface Props {
  type?: NDAlertType
  icon?: string
  iconMargin?: string
}

const props = withDefaults(defineProps<Props>(), {
  type: 'success',
  iconMargin: '16px 12px 0px 20px',
})
const themeOverrides: GlobalThemeOverrides = {
  Alert: {
    iconMargin: props.iconMargin,
  },
}

const customAlertClass = computed(() => {
  switch (props.type) {
    case 'favorite':
      return 'text-yellow-50 bg-orange-10'
    default:
      return ''
  }
})

const isNaiveUI = (type: NDAlertType): type is AlertProps['type'] => {
  return /^(default|error|info|success|warning)$/.test(type || '')
}
</script>
