<template>
  <div class="navbar-user-button flex items-center">
    <template v-if="showMemberBtn">
      <n-popover
        v-if="!app.isMobile && !app.isTablet"
        :show-arrow="false"
        trigger="manual"
        :show="isMenuShow"
        placement="bottom-end"
        :z-index="zIndex.navbarPopover"
        @clickoutside="closeMenu"
      >
        <template #trigger>
          <nd-button type="icon" :icon="iconName" class="!text-black-50" @click="toggleMenu" />
        </template>
        <template #default>
          <member-route-list class="w-80 mt-5" is-navbar @close-menu="closeMenu" @link-clicked="closeMenu" />
        </template>
      </n-popover>
      <template v-else>
        <nd-button type="icon" :icon="iconName" class="!text-black-50" @click="toggleMenu" />
        <n-drawer v-model:show="isMenuShow" width="100%" placement="right" :z-index="zIndex.drawer">
          <n-drawer-content>
            <div class="close-btn flex justify-end py-2 px-3">
              <nd-img src="@/assets/icons/cancel.svg" class="w-10 h-10 p-2 ml-auto cursor-pointer" @click="closeMenu" />
            </div>
            <member-route-list class="w-72 w-full scrollbar" is-navbar @close-menu="closeMenu" @link-clicked="closeMenu" />
          </n-drawer-content>
        </n-drawer>
      </template>
    </template>
    <template v-else>
      <login-button class="-mr-4 max-w-160px" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { NPopover, NDrawer, NDrawerContent } from 'naive-ui'

import { useAppStore } from '@/store/app'
import { useAuthStore } from '@/store/auth'

import MemberRouteList from '@/components/member/member-route-list.vue'
import LoginButton from '@/components/login/login-button.vue'

import { zIndex } from '@/theme.config'
import type { AuthStep } from '@/types/auth'
import { isAuthStep } from '@/types/auth'

const route = useRoute()
const authStore = useAuthStore()

const { isLogin, authStep, isLoginModalShown } = storeToRefs(authStore)

const app = useAppStore()

const isMenuShow = ref(false)
const iconName = computed(() => (app.isExtraLargeDesktop ? 'person' : 'menu'))
const showMemberBtn = computed(() => isLogin.value || !app.isExtraLargeDesktop)
const isMenuTriggerClick = refAutoReset(false, 300) // 避免 closeMenu 時觸發 toggleMenu
const closeMenu = () => {
  isMenuShow.value = false
  isMenuTriggerClick.value = true
}
const toggleMenu = () => {
  if (isMenuTriggerClick.value) {
    return
  }
  isMenuShow.value = !isMenuShow.value
}

onMounted(() => {
  const modal = route.query.m as string

  if (!isLogin.value && modal === 'auth') {
    let step = route.query.step as AuthStep
    if (!isAuthStep(step)) step = 'sign-up'

    isLoginModalShown.value = true
    authStep.value = step
  }
  if (modal) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { m: _m, step: _step, ...restQuery } = route.query
    navigateTo({ query: restQuery, replace: true })
  }
})
</script>

<style lang="scss" scoped></style>
