<template>
  <div class="bg-white rounded">
    <client-only>
      <div :class="avatarContainerClass">
        <nd-img
          :src="avatar"
          alt="avatar"
          :width="isNavbar ? (app.isMobile || app.isTablet ? 60 : 48) : 120"
          :height="isNavbar ? (app.isMobile || app.isTablet ? 60 : 48) : 120"
          class="aspect-square rounded-full object-cover"
          :class="avatarClasses"
        />
        <div class="flex flex-col">
          <h3 class="text-lg font-medium" :class="nicknameClass">
            {{ userNickname }}
          </h3>
          <div v-if="isLogin" class="flex items-center" :class="isNavbar ? 'h-8 md:h-auto' : 'justify-center mt-2'">
            <nd-img :src="levelIcon" :alt="levelName" class="w-6 h-6 mr-1" />
            <span :class="[levelColorClass, isNavbar ? 'text-sm' : 'text-base']">{{ levelName }}</span>
          </div>
        </div>
        <locale-link v-if="!isNavbar" to="/member/personal-info" class="text-black-50!">
          <div class="flex justify-center items-center gap-1 mt-2 py-1 px-3">
            <h4 class="text-base">{{ $t('edit_personal_info') }}</h4>
            <svg-icon class="w-5 h-5" name="arrow-right" />
          </div>
        </locale-link>
        <div v-if="isNavbar" class="close-btn flex-1 hidden md:block">
          <nd-img src="@/assets/icons/cancel.svg" class="w-10 h-10 p-2 ml-auto -mr-2 cursor-pointer" @click.stop="closeMenu" />
        </div>
      </div>
    </client-only>
    <div class="pb-5">
      <ul>
        <hr v-if="isNavbar && isLogin" class="mb-2" />
        <li v-if="isLogin" @click="linkClicked">
          <locale-link to="/member/fun-coin">
            <div class="flex items-center justify-between px-5 py-3">
              <div class="flex items-center gap-2">
                <svg-icon name="fun-coin" class="w-6 h-6" />
                <span class="text-base">{{ $t('personal_info_side_bar_funcoin') }}</span>
              </div>
              <div class="amount text-base">{{ priceFormat(user?.bonus) }}</div>
            </div>
          </locale-link>
        </li>
        <li v-if="isLogin" @click="linkClicked">
          <locale-link to="/member/funmiles">
            <div class="flex items-center justify-between px-5 py-3">
              <div class="flex items-center gap-2">
                <svg-icon name="funmiles" class="w-6 h-6" />
                <span class="text-base">{{ $t('all_funmiles') }}</span>
              </div>
              <div class="amount text-base">{{ funmilesWording }}</div>
            </div>
          </locale-link>
        </li>
        <li v-if="isLogin" @click="linkClicked">
          <locale-link to="/member/funmiles-rewards">
            <div class="flex items-center justify-between px-5 py-3">
              <div class="flex items-center gap-2">
                <svg-icon name="funmiles-rewards" class="w-6 h-6" />
                <span class="text-base">{{ $t('all_funmiles_rewards') }}</span>
              </div>
              <div v-if="isNavbar" class="decoration-btn text-base -mr-2">
                <svg-icon name="arrow-right" class="w-6 h-6" />
              </div>
            </div>
          </locale-link>
        </li>
        <hr class="my-2" />
        <li v-for="memberRoute in memberRouteList" :key="memberRoute.title" class="cursor-pointer" @click="memberRoute.onClick">
          <component :is="memberRoute.component" v-if="memberRoute.show" class="block flex justify-between gap-2 px-5 py-3" :to="memberRoute.to">
            <div class="flex items-center block">
              <div>
                <svg-icon :name="memberRoute.icon" :alt="memberRoute.title" class="mr-2 w-6 h-6" />
              </div>

              <span class="text-base">{{ memberRoute.title }}</span>
            </div>
            <div v-if="isNavbar" class="decoration-btn text-base -mr-2">
              <svg-icon name="arrow-right" class="w-6 h-6" />
            </div>
          </component>
        </li>
        <line-banner v-if="showLineBanner" :closable="false" class="pr-5 pl-5 py-4" line-icon-size="6" />
        <div
          v-if="isNavbar && isLogin"
          v-gtm:click="GtmEvent.ACCOUNT_LOGGED_OUT"
          class="flex items-center px-5 mt-2 pt-5 border-t-1 cursor-pointer"
          @click="clickLogout"
        >
          <span class="text-base">{{ $t('all_log_out') }}</span>
        </div>
        <div v-else-if="isNavbar && !isLogin" class="mt-2 border-t-1">
          <login-button class="w-full !p-5 !pb-0 !justify-left !bg-transparent" @login-btn-click="closeMenu" />
        </div>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { useAppStore } from '@/store/app'
import { useAuthStore } from '@/store/auth'

import { AuthType } from '@/types/member'
import { GtmEvent } from '@/types/gtm'

import LocaleLink from '@/components/global/locale-link.vue'
import LineBanner from '@/components/social/line-banner.vue'
import LoginButton from '@/components/login/login-button.vue'

const { t } = useI18n()

const route = useRoute()

const app = useAppStore()
const authStore = useAuthStore()

const { isLogin, user } = storeToRefs(authStore)
const { avatarImagePath, avatarBorderClass, levelName, levelColorClass, levelIcon, linearGradientClass } = useMembershipLevel(user)

const props = withDefaults(
  defineProps<{
    isNavbar?: boolean
  }>(),
  {
    isNavbar: false,
  }
)

const emit = defineEmits<{
  (event: 'close-menu'): void
  (event: 'link-clicked'): void
}>()
const closeMenu = () => emit('close-menu')
const linkClicked = () => emit('link-clicked')

const { selectedLangOption } = useLangSelector()

const memberRouteList = computed(() => [
  {
    to: '/member/orders',
    icon: 'order',
    title: t('all_order'),
    show: isLogin.value,
    component: LocaleLink,
    onClick: linkClicked,
  },
  {
    to: '/member/coupons',
    icon: 'coupon',
    title: t('personal_info_side_bar_my_coupons'),
    show: isLogin.value,
    component: LocaleLink,
    onClick: linkClicked,
  },
  {
    to: '/member/favorites',
    icon: 'heart-line',
    title: t('personal_info_side_bar_my_favorite'),
    show: isLogin.value,
    component: LocaleLink,
    onClick: linkClicked,
  },
  {
    to: '/member/personal-info',
    icon: 'person',
    title: t('personal_info_side_bar_personal_info'),
    show: isLogin.value,
    component: LocaleLink,
    onClick: linkClicked,
  },
  {
    to: '/member/account-settings',
    icon: 'creditcard',
    title: t('credit_card_and_account_security'),
    show: isLogin.value,
    component: LocaleLink,
    onClick: linkClicked,
  },
  {
    icon: 'language',
    title: selectedLangOption.value?.tag || '',
    show: !app.isExtraLargeDesktop && props.isNavbar,
    component: 'div',
    onClick: onLangClick,
  },
])

const showLineBanner = computed(() => props.isNavbar && app.isLargeDesktop)
const avatarContainerClass = computed(() => (props.isNavbar ? 'flex items-center h-20 mx-5' : `py-6 ${linearGradientClass.value}`))
const avatarClasses = computed(() => {
  const res = [props.isNavbar ? 'mr-3' : 'mx-auto']
  if (!(isLogin.value && fbAvatar.value)) {
    return res
  }

  res.push(props.isNavbar ? 'border-2' : 'border-4')
  res.push(avatarBorderClass.value)
  return res
})
const nicknameClass = computed(() => {
  if (!props.isNavbar) {
    return 'order-1 mt-6 text-center'
  }
  return isLogin.value ? 'order-0 mb-1' : ''
})
const userNickname = computed(() => (isLogin.value ? authStore.userNickname : t('personal_info_guest')))

const fbAvatar = computed(() => {
  const fbId = user.value?.account
  const isFbLogin = user.value?.auth_type === AuthType.FACEBOOK
  return fbId && isFbLogin ? `https://graph.facebook.com/${fbId}/picture?type=large&width=240&height=240` : ''
})
const avatar = computed(() => {
  const GUEST_AVATAR = '/images/avatar-guest.png'
  return isLogin.value ? fbAvatar.value || avatarImagePath.value : GUEST_AVATAR
})

const funmilesWording = computed(() =>
  user.value?.benefit?.mile && user.value?.benefit?.mile > 999_999 ? '1M+' : priceFormat(user.value?.benefit?.mile)
)

const clickLogout = () => {
  if ((route.meta?.middleware as string | string[])?.includes('auth-required')) {
    authStore.logout(false)
    window.location.replace('/')
  } else {
    authStore.logout()
    closeMenu()
  }
}

const onLangClick = () => {
  if (app.isDesktopScreen) {
    closeMenu()
  }
  app.showLangSwitcher = true
}
</script>

<style lang="scss" scoped></style>
