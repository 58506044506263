<template>
  <header class="main-navbar h-16 bg-white z-navbar">
    <div class="container py-3 mx-auto">
      <div class="flex justify-between px-4 gap-6">
        <div class="left-group flex items-center">
          <div class="logo w-120px h-10 flex-[0_0_auto]">
            <locale-link to="/">
              <nd-img src="@/assets/images/logo.svg" alt="Niceday Logo" class="text-yellow-50" />
            </locale-link>
          </div>
          <navbar-region-selector v-if="isIndexPage" />
          <client-only>
            <categories-selector v-if="isShowCategoriesSelector" class="ml-14 max-w-[190px]" />
          </client-only>
        </div>

        <div class="actions flex-1 flex items-center justify-end h-10 sm:-mr-2">
          <client-only>
            <navbar-search-button v-if="isShowNavbarSearchButton" class="flex justify-end flex-1 mr-6" />
            <line-button v-if="isShowLineButton" />
            <mobile-search-button v-if="isShowMobileSearchButton" class="sm:mr-6" />
            <navbar-lang-selector v-if="!isBookingPage && app.isExtraLargeDesktop" />
            <navbar-user-button v-if="!isBookingPage" />
          </client-only>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useAppStore } from '@/store/app'
import { useRegionStore } from '@/store/region'

import CategoriesSelector from '@/components/common/categories-selector.vue'
import LineButton from '@/components/social/line-button.vue'
import MobileSearchButton from '@/components/search/mobile-search-button.vue'
import NavbarSearchButton from '@/components/common/navbar-search-button.vue'
import NavbarUserButton from '@/components/common/navbar-user-button.vue'
import NavbarRegionSelector from '@/components/common/navbar-region-selector.vue'
import NavbarLangSelector from '@/components/common/navbar-lang-selector.vue'

const route = useRoute()
const app = useAppStore()
const regionStore = useRegionStore()

const productListPageRegex = /\/regions\/\d+\/[categories|themes|search]/
const isSearchResultPage = computed(() => productListPageRegex.test(route.path))

const bookingPageRegex = /\/booking\//
const isBookingPage = computed(() => bookingPageRegex.test(route.path))
const isIndexPage = computed(() => /^(index|regions-rid)__/.test(route.name as string))

const isShowLineButton = computed(() => app.isExtraLargeDesktop && !isBookingPage.value && regionStore.isTWRegion)
const isShowMobileSearchButton = computed(() => /^index___/.test(route.name as string) && (app.isMobile || app.isTablet))

const isShowNavbarSearchButton = computed(() => app.isDesktopScreen && isSearchResultPage.value)
const isShowCategoriesSelector = computed(() => app.isDesktopScreen && isSearchResultPage.value)
</script>

<style lang="scss" scoped>
.main-navbar {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
</style>

<style lang="scss"></style>
