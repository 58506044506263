<template>
  <div v-if="showLineBanner" class="line-banner flex justify-between items-center gap-2 pl-1 py-4 pr-4 bg-#edfbf5">
    <div class="info flex items-center">
      <svg-icon v-if="closable" name="cancel" class="w-4 h-4 text-black-50 mr-1" @click="hideBanner" />
      <svg-icon name="line" :class="lineIconClasses" />
      <p class="text-sm ml-2 flex-1">{{ $t('subscribe_line_group_content') }}</p>
    </div>
    <div class="action text-green-50 border-green-50 border-1 py-0.5 px-3 rounded" @click="hideBanner">
      <a href="https://maac.io/19xdB" target="_blank">
        <span class="whitespace-nowrap">{{ $t('all_add_in') }}</span>
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRegionStore } from '@/store/region'

interface Props {
  lineIconSize?: number | string
  closable?: boolean
}

const props = withDefaults(defineProps<Props>(), { closable: true, lineIconSize: 10 })

const regionStore = useRegionStore()

const { bannerCookie, isShowLineBanner } = useLineBanner()

const showLineBanner = computed(() => (props.closable ? isShowLineBanner.value : regionStore.isTWRegion))
const lineIconClasses = computed(() => [`w-${props.lineIconSize}`, `h-${props.lineIconSize}`])

const hideBanner = () => {
  if (!props.closable) return

  bannerCookie.value = { isShow: false }
}
</script>

<style lang="scss" scoped></style>
