<template>
  <navbar-selector
    :value="locale"
    class="lang-selector max-w-120px"
    :options="displayOptions"
    :render-tag="renderSelectorTag"
    @update:value="changeLang"
  />
</template>

<script setup lang="ts">
import NavbarSelector from './navbar-selector.vue'

const { locale, allowLangOptions, changeLang } = useLangSelector()

const preAllowLangOptions = usePrevious(allowLangOptions)

/**
 * 因 `locale` 不會即時更新，會有一小段時間在 `allowLangOptions` 中匹配不到值。
 * 故在匹配不到時先使用前一個 options，來避免 UI 顯示錯誤。
 */
const displayOptions = computed(() => {
  const hasMatchingLocaleOption = allowLangOptions.value.some(opt => opt.value === locale.value)

  return hasMatchingLocaleOption ? allowLangOptions.value : preAllowLangOptions.value
})

const renderSelectorTag = ({ option }: { option: (typeof allowLangOptions.value)[number] }) => option.tag
</script>
