<template>
  <div class="line-button max-w-276px">
    <n-popover
      trigger="manual"
      placement="bottom-end"
      :show="isPopoverShow"
      :show-arrow="false"
      :z-index="zIndex.navbarPopover"
      @clickoutside="hidePopover"
    >
      <template #trigger>
        <button
          href="https://maac.io/19xdB"
          target="_blank"
          class="h-10 px-4 py-2 flex items-center gap-2 no-underline text-lg leading-6 rounded hover:bg-#f2fbf6 active:bg-#caeed9 text-black-60 border border-solid border-green-60 transition-colors whitespace-nowrap"
          @click="togglePopover"
        >
          <svg-icon name="line" class="w-6 h-6 flex-shrink-0" />
          <span class="line-clamp-1 whitespace-normal">{{ $t('header_subscribe_line_group_btn') }}</span>
        </button>
      </template>
      <template #default>
        <div class="line-button-popup w-110 max-w-90dvw">
          <div class="header flex justify-between items-center gap-2 pt-4 px-5 pb-2">
            <span class="title text-lg font-500">{{ $t('header_subscribe_line_group_btn') }}</span>
            <nd-button type="icon" icon="cancel" class="text-black-50 -mr-2" @click="hidePopover" />
          </div>
          <div class="content pt-5 px-5">
            <div class="flex items-center gap-3">
              <div class="p-3px">
                <div class="qrcode w-100px h-100px bg-green-60"></div>
              </div>
              <p class="flex-1 text-base font-500 text-green-60">{{ $t('subscribe_line_group_content') }}</p>
            </div>
            <div class="decoration-image">
              <nd-img src="@/assets/images/marketing/line-popup-image.svg" />
            </div>
          </div>
        </div>
      </template>
    </n-popover>
  </div>
</template>

<script lang="ts" setup>
import { NPopover } from 'naive-ui'

import { zIndex } from '@/theme.config'

const isPopoverShow = ref(false)

const togglePopover = () => {
  isPopoverShow.value = !isPopoverShow.value
}
const hidePopover = () => {
  isPopoverShow.value = false
}
</script>

<style lang="scss" scoped>
.line-button-popup {
  .qrcode {
    mask-image: url('/images/marketing/line-popup-qrcode.png');
    mask-size: contain;
  }
}
</style>
