<template>
  <nd-select
    v-model:value="value"
    class="navbar-selector !w-auto"
    :options="props.options"
    :consistent-menu-width="false"
    :to="false"
    placement="bottom-end"
    :with-border="false"
    :n-ui-theme-overrides="nUiThemeOverrides"
  />
</template>

<script setup lang="ts">
import type { GlobalThemeOverrides } from 'naive-ui'

import { colors } from '@/theme.config'
import NdSelect from '@/components/global/nd-select.vue'

type NdSelectProps = InstanceType<typeof NdSelect>['$props']

interface Props {
  options: NdSelectProps['options']
}

const value = defineModel<string>()

const props = defineProps<Props>()

const nUiThemeOverrides = computed<GlobalThemeOverrides>(() => ({
  Select: {
    peers: {
      InternalSelectMenu: {
        optionColorActivePending: 'none',
        optionColorPending: colors.gray[20],
      },
    },
  },
}))
</script>

<style lang="scss" scoped>
:deep(.navbar-selector) {
  .n-base-select-option__content {
    @apply w-50;
  }
}
</style>
