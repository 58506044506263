<template>
  <nd-button :truncate-text="true" type="text" @click="onLoginBtnClick">
    {{ $t('hearder_register_login') }}
  </nd-button>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/store/auth'

interface Emits {
  (event: 'login-btn-click'): void
}

const emit = defineEmits<Emits>()

const authStore = useAuthStore()

const onLoginBtnClick = () => {
  emit('login-btn-click')
  authStore.openLoginModal()
}
</script>
