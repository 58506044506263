import { useRegionStore } from '@/store/region'

export function useLineBanner() {
  const regionStore = useRegionStore()

  const COOKIE_NAME = 'niceday_line_banner'
  const COOKIE_MAX_AGE = 60 * 60 * 24 * 90 // 90 days

  const bannerCookie = useCookie<{ isShow: boolean }>(COOKIE_NAME, {
    maxAge: COOKIE_MAX_AGE,
    default: () => ({ isShow: true }),
  })

  const isShowLineBanner = computed(() => bannerCookie.value.isShow && regionStore.isTWRegion)

  return { bannerCookie, isShowLineBanner }
}
