<template>
  <div class="navbar-search-button">
    <nd-button v-show="!isShowSearchInput" type="icon" icon="search" @click="handleClickSearchButton" />
    <n-popover trigger="manual" :show="isFocusOnSearchInput" :show-arrow="false" placement="bottom-start" :z-index="zIndex.navbarPopover">
      <template #trigger>
        <nd-input
          v-show="isShowSearchInput"
          ref="searchInputRef"
          v-model="keyword"
          :placeholder="$t('all_search_placeholder')"
          clearable
          @focus="handleFocus"
          @blur="handleBlur"
          @submit="handleSearch"
          @clickoutside="handleBlur"
        >
          <template #prefix>
            <svg-icon name="search" class="w-5 h-5 mr-4" />
          </template>
        </nd-input>
      </template>
      <template #default>
        <div class="search-hint-wrapper min-w-400px" :class="isShowSearchInput ? '' : 'hidden'" :style="searchHintWrapperStyle">
          <search-hint @click-keyword="handleClickKeyword" />
        </div>
      </template>
    </n-popover>
  </div>
</template>

<script setup lang="ts">
import { NPopover } from 'naive-ui'
import type { MaybeComputedElementRef } from '@vueuse/core'
import { useElementSize } from '@vueuse/core'

import NdInput from '@/components/form/nd-input.vue'
import SearchHint from '@/components/search/search-hint.vue'

import { zIndex } from '@/theme.config'
import { GtmEvent } from '@/types/gtm'

const route = useRoute()
const router = useLocaleRouter()
const searchRecord = useSearchRecord()

const searchInputRef = ref<Nullable<typeof NdInput>>(null)

const isFocusOnSearchInput = ref(false)

const keyword = ref(route.query.keyword as string)
const { regionId } = useRegion()

const { width: inputWidth } = useElementSize(searchInputRef as MaybeComputedElementRef)

const searchHintWrapperStyle = computed(() => ({
  width: `${inputWidth.value}px`,
}))

const isShowSearchInput = computed(() => !!keyword.value || isFocusOnSearchInput.value)

const handleClickSearchButton = () => {
  if (searchInputRef.value) {
    handleFocus()
    nextTick(() => {
      searchInputRef.value!.focus()
    })
  }
}

const handleFocus = () => {
  isFocusOnSearchInput.value = true
}

const handleBlur = () => {
  // FIXME: 先用 setTimeout 解決點擊 search hint 會觸發 blur 的問題；不是最佳解，但目前沒時間研究
  setTimeout(() => {
    isFocusOnSearchInput.value = false
  }, 100)
}

const handleClickKeyword = (selectedKeyword: string) => {
  keyword.value = selectedKeyword
  isFocusOnSearchInput.value = false
  handleSearch()
}

const handleSearch = () => {
  if (!keyword.value) return

  searchRecord.add(keyword.value)

  useNuxtApp().$trackEvent({
    event: GtmEvent.SEARCH_QUERY_SENT,
    payload: {
      keyword: keyword.value,
    },
  })

  // 判斷目前在哪個頁面的搜尋網址
  let regionSearchSubPath = `/regions/${regionId.value}/search`
  if (route.params.cid) {
    regionSearchSubPath = `/regions/${regionId.value}/categories/${route.params.cid}`
  } else if (route.params.tid) {
    regionSearchSubPath = `/regions/${regionId.value}/themes/${route.params.tid}`
  }

  router.push({ path: regionSearchSubPath, query: { keyword: keyword.value } })
}
</script>

<style lang="scss" scoped></style>
