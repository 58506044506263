<template>
  <div v-if="appStore.isDesktopScreen" class="ml-14">
    <navbar-selector :value="currentRegionId" class="region-selector max-w-140px" :options="regionOptions" @update:value="changeRegion" />
  </div>
  <template v-else>
    <nd-button v-if="selectedRegionOption" text-class="flex items-center" @click="toggleMenu">
      <span class="max-w-19 line-clamp-1 whitespace-normal flex-1">{{ selectedRegionOption.label }}</span>
      <svg-icon name="arrow-down" class="w-6 h-6 ml-2" />
    </nd-button>
    <nd-drawer v-model:show="showRegionDrawer" width="100%" placement="right" :z-index="zIndex.drawer">
      <div class="close-btn flex justify-end py-2 px-3">
        <nd-img src="@/assets/icons/cancel.svg" class="w-10 h-10 p-2 ml-auto cursor-pointer" @click="closeMenu" />
      </div>
      <div class="py-2">
        <div
          v-for="option in regionOptions"
          :key="option.value"
          class="w-full px-4 py-3 justify-left text-base cursor-pointer"
          :class="{ 'text-yellow-50': option.value === currentRegionId }"
          @click="changeRegion(option.value, closeMenu)"
        >
          {{ option.label }}
        </div>
      </div>
    </nd-drawer>
  </template>
</template>

<script setup lang="ts">
import NavbarSelector from './navbar-selector.vue'
import NdDrawer from './nd-drawer.vue'

import { useAppStore } from '@/store/app'
import { useRegionStore } from '@/store/region'

import { zIndex } from '@/theme.config'

const appStore = useAppStore()
const regionStore = useRegionStore()
const { regionOptions, selectedRegionOption, currentRegionId } = storeToRefs(regionStore)

const { changeRegion } = useRegion()

const showRegionDrawer = ref(false)

const toggleMenu = () => {
  showRegionDrawer.value = !showRegionDrawer.value
}

const closeMenu = () => {
  showRegionDrawer.value = false
}
</script>
